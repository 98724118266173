import './App.css';
import { Route, Routes, Link } from 'react-router-dom';
import Home from "./Home"
import About from "./About"
import React from 'react' 
import Sponsor from "./Sponsor"

function App() {
  return (
    <div>
      <div className="nav-bar">
        <Link className="link" to="/">HOME</Link>
        <Link className="link" to="https://www.marriott.com/event-reservations/reservation-link.mi?id=1728403540091&key=GRP&guestreslink2=true">HOTEL RESERVATIONS</Link>
        <Link className="link" to="/sponsor">SPONSOR INFO</Link>
        <Link className="link" to="/about">ABOUT</Link>
        <Link className="link" to="https://www.facebook.com/profile.php?id=100087806125957">CONNECT WITH US ON FACEBOOK</Link>
      </div>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/sponsor" element={<Sponsor />} />
      </Routes>
    </div>
  );
}

export default App;
