import React from 'react'
import './About.css'

function About () {

    return (
        <div className="about">
            <p className="welcome"><span className="emphasis">Welcome to Michigan Florida Snowbirds, LLC.</span> a dedicated organization focused on making a positive impact through golf! </p>
            <div className="about-cards">
                <div className="about-card">
                    <div className="about-image">
                        <img src="/who.png" alt="Michigan Snowbirds Group" className="about-pic"></img>
                    </div>
                    <div className='about-caption'>
                        <p id="about-caption-heading">Annual Golf Tournament</p>
                        <p id="about-caption-content">The Michigan Florida Snowbirds have come together again to host an exciting three-day weekend at the beautiful Martin Downs Golf Club in Palm City, Florida. Martin Downs is one of the very few Black-owned Golf Clubs in the United States.</p>
                    </div>
                </div>
                <div className="about-card">
                    <div className="about-image">
                        <img src="/fun.png" alt="Michigan Snowbirds Group" className="about-pic"></img>
                    </div>
                    <div className='about-caption'>
                        <p id="about-caption-heading">Giving Back</p>
                        <p id="about-caption-content">Each year, we proudly host an annual golf tournament to support Historically Black Colleges and Universities (HBCUs) and their golf programs. Our goal is to provide resources, scholarships, and opportunities to students and athletes at HBCUs, helping them excel both on the golf course and in their academic pursuits.</p>
                    </div>
                </div>
                <div className="about-card">
                    <div className="about-image">
                        <img src="/education.png" alt="Michigan Snowbirds Group" className="about-pic"></img>
                    </div>
                    <div className='about-caption'>
                        <p id="about-caption-heading">Golf & the Impact on Education</p>
                        <p id="about-caption-content">We believe in the power of education and sports to open doors and create lifelong opportunities. Through our annual tournament and fundraising efforts, we aim to strengthen HBCU golf programs, ensuring that these institutions and their talented students receive the support they deserve.</p>
                    </div>
                </div>
            </div>
            <div className="footnote">
                <img src="mfsb logo.png" style={{height:300}} alt="Michigan Florida Snowbird Logo"></img>
                <div><h1 className="footnote-header">2025 HOST COMMITTEE</h1> 
                <p className="commitee">John & Robin Cash <span className="emphasis">Rodney Glasgow</span></p> 
                <p className="commitee">Tammy & Florida Golden <span className="emphasis">Rick Jones</span></p>
                <p className="commitee">Daralene Nero <span className="emphasis">Vivian Pickard</span></p>
                <p className="commitee">Leon & Cheryl Richardson <span className="emphasis">Janis Rogers</span></p>
                <p className="commitee">Linda Ware Smith</p></div>
                
            </div>
        </div>
    )
}

export default About